// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic&subset=latin-ext");
@use "@angular/material" as mat;
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic&amp;subset=latin-ext');

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Noto+Sans&family=Noto+Sans+TC&family=Noto+Sans+SC&family=Noto+Sans+JP&family=Noto+Sans+KR&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

// @font-face{
//   font-family: 'Noto Sans CJK {JP, KR, SC, TC}';
//   font-style: normal;
//   font-weight: 400;
//   src: local('☺'),
//        url('NotoSansCJK{jp, kr, sc, tc}.woff2') format('woff2'),
//        url('NotoSansCJK{jp, kr, sc, tc}.woff') format('woff'),
//        url('NotoSansCJK{jp, kr, sc, tc}.otf') format('opentype');
// }

// $font-family: '"Open Sans", "Helvetica Neue", sans-serif',
$general-typography: mat.define-typography-config(
  $font-family: '"Lato", "Noto Sans", "Noto Sans TC", "Noto Sans SC", "Noto Sans JP", "Noto Sans KR",sans-serif',
);

//'-apple-system, "Helvetica Neue", "Lucida Grande", Helvetica, "微軟正黑體", "Microsoft Jhenghei", "Open Sans", Arial, sans-serif, "新細明體", "PMingLiU","細明體", "MingLiU", "儷黑 Pro", "LiHei Pro","文泉驛正黑", "WenQuanYi Zen Hei"',

body,
pre {
  font-family: "Lato", "Noto Sans", "Noto Sans TC", "Noto Sans SC", "Noto Sans JP", "Noto Sans KR", sans-serif;
  // font-family: "Lato", "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK SC", "Noto Sans CJK TC", sans-serif;
}

// -apple-system, "Helvetica Neue", "Lucida Grande", Helvetica, "微軟正黑體", "Microsoft Jhenghei",
//     "Open Sans", Arial, sans-serif, "新細明體", "PMingLiU", "細明體", "MingLiU", "儷黑 Pro", "LiHei Pro", "文泉驛正黑",
//     "WenQuanYi Zen Hei";

// Be sure that you only ever include this mixin once!
@include mat.core($general-typography);

@import "custom-color";

$web-material-app-primary: mat.define-palette($mt-app-primary);
$web-material-app-accent: mat.define-palette($mt-app-accent, 500, 200, 700);

// The warn palette is optional (defaults to red).
$web-material-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$web-material-app-theme: mat.define-light-theme(
  $web-material-app-primary,
  $web-material-app-accent,
  $web-material-app-warn
);
// #8d6e63

// // Define an alternate dark theme.
// $dark-primary: mat-palette($mat-blue-grey);
// $dark-accent:  mat-palette($mat-amber, A200, A100, A400);
// $dark-warn:    mat-palette($mat-deep-orange);
// $dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-material-app-theme);

// additional colors
// https://github.com/angular/components/issues/11888#issuecomment-536509515

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: rgba($color: #000000, $alpha: 0.5);
  }

  &:not(.mat-form-field-has-label) .mat-form-field-infix {
    padding: 0.5em 0 1em 0;
  }

  .mat-select-arrow-wrapper {
    transform: none !important;
  }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix .mat-flat-button.mat-yellow,
.mat-flat-button.mat-yellow,
.mat-fab.mat-yellow,
.mat-mini-fab.mat-yellow {
  background-color: #ffff01;
}

.mat-flat-button.mat-gray,
.mat-flat-button.mat-gray,
.mat-fab.mat-gray,
.mat-mini-fab.mat-gray,
.mat-button.mat-gray {
  background-color: #777777;
  color: white;
}

.mat-flat-button.mat-black,
.mat-flat-button.mat-black,
.mat-fab.mat-black,
.mat-mini-fab.mat-black,
.mat-button.mat-black {
  background-color: #000;
  color: white;
}

// @include mat-datepicker-theme($web-material-app-theme); // included by angular-material-theme

// .test{

//     $custom-typography: mat-typography-config(
//         $font-family:   'Roboto',
//         $display-4:     mat-typography-level(7rem, 7rem, 300),
//         $display-3:     mat-typography-level(3.5rem, 3.5rem, 400),
//         $display-2:     mat-typography-level(2.813rem, 3rem, 400),
//         $display-1:     mat-typography-level(2.125rem, 2.5rem, 400),
//         $headline:      mat-typography-level(1.5rem, 2rem, 400),
//         $title:         mat-typography-level(1.25rem, 2rem, 500),
//         $subheading-2:  mat-typography-level(1rem , 1.75rem, 400),
//         $subheading-1:  mat-typography-level(0.9375rem, 1.5rem, 400),
//         $body-2:        mat-typography-level(0.875rem, 1.5rem, 500),
//         $body-1:        mat-typography-level(0.875rem, 1.25rem, 400),
//         $caption:       mat-typography-level(0.75rem, 1.25rem, 400),
//         $button:        mat-typography-level(0.875rem, 0.875rem, 500),
//         $input:         mat-typography-level(inherit, 1.125, 400)
//     );

//     @include angular-material-typography($custom-typography);
// }

// $color-primary: mat-color($mat-blue-grey, 500);
// $color-primary-active: mat-color($mat-blue-grey, 800);
// $color-accent: mat-color($mat-brown, A400);
// $color-accent-active: mat-color($mat-brown, A700);
// $color-warn: mat-color($mat-red, 500);

:root {
  --color-primary: #{$color-primary};
  --color-accent: #{$color-accent};
  --color-warn: #{$color-warn};
}

.background-color-primary {
  background-color: $color-primary;
  color: white;

  mat-button-toggle {
    color: white;
  }
  .mat-button-toggle-checked {
    background-color: $color-primary-active;
  }
}

.background-color-accent {
  background-color: $color-accent;
  color: white;

  mat-button-toggle {
    color: white;
  }
  .mat-button-toggle-checked {
    background-color: $color-accent-active;
  }
}

// .background-color-warn {
//   // background-color: $color-warn;

//   .mat-simple-snackbar-action {
//     color: white;
//   }
// }

.color-primary {
  color: $color-primary;
  color: var(--color-primary);
}

.color-accent {
  color: $color-accent;
  color: var(--color-accent);
}

.color-warn {
  color: $color-warn;
  color: var(--color-warn);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.8 !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.no-hover-effect.mat-button:hover .mat-button-focus-overlay,
.no-hover-effect.mat-stroked-button:hover .mat-button-focus-overlay {
  opacity: 0;
  background-color: transparent;
}

.full-screen-dialog {
  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-content {
    margin: 0;
  }
}

.mat-icon.mat-icon-inline.icon-large {
  font-size: 2.5rem;
}

// button:not(.mat-fab):not(.mat-mini-fab) {
//   border-radius: 0 !important;
// }

html {
  .mat-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-flat-button {
    border-radius: 0;
  }

  .mat-snack-bar-container {
    width: 100vw;
    max-width: 100vw;
    background-color: black;
    margin: 0;
    padding: 0;
    min-height: 0;
    max-height: 48px;

    &.background-color-warn {
      background-color: #ff0000;
      color: white;

      .mat-simple-snackbar-action {
        color: white;
      }
    }
  }

  // .mat-slide-toggle-ripple,
  // .mat-ripple {
  //   display: none;
  // }

  .mat-bottom-sheet-container {
    &.full-width-bottom-sheet {
      // min-width: 100vw;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100vw;
    }

    &.no-padding {
      padding: 0;
    }

    @media screen and (max-width: 599px) {
      &.bottom-sheet-xs-max-90-vh {
        max-height: 90vh;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
  }
}

mat-form-field ngx-mat-intl-tel-input .country-selector {
  margin-top: -0.5em;
}
