// https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px,
  xl: 5001px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

// flex-layout breakpoints
// xs
@mixin xs {
  @include respond-below(xs) {
    @content;
  }
}

// sm
@mixin sm {
  @include respond-between(xs, sm) {
    @content;
  }
}

// md
@mixin md {
  @include respond-between(sm, md) {
    @content;
  }
}

// lg
@mixin lg {
  @include respond-between(md, lg) {
    @content;
  }
}

// xl
@mixin xl {
  @include respond-between(lg, xl) {
    @content;
  }
}

// lt-sm
@mixin lt-sm {
  @include respond-below(sm) {
    @content;
  }
}

// lt-md
@mixin lt-md {
  @include respond-below(md) {
    @content;
  }
}

// lt-lg
@mixin lt-lg {
  @include respond-below(lg) {
    @content;
  }
}

// lt-xl
@mixin lt-xl {
  @include respond-below(xl) {
    @content;
  }
}

// gt-xs
@mixin gt-xs {
  @include respond-above(xs) {
    @content;
  }
}

// gt-sm
@mixin gt-sm {
  @include respond-above(sm) {
    @content;
  }
}

// gt-md
@mixin gt-md {
  @include respond-above(md) {
    @content;
  }
}

// gt-lg
@mixin gt-lg {
  @include respond-above(lg) {
    @content;
  }
}

// @import "../responsive-breakpoints.scss";

// * {
//   opacity: 0.6;

//   @include xs() {
//     opacity: 0.8;
//   }
// }

// *{
//   opacity: 0.6;
// }

// @include xs() {
//   * {
//     opacity: 0.8;
//   }
// }
