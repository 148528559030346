// Reference mat-palette generator http://mcg.mbitson.com/

$mt-app-primary: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #262626,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mt-app-accent: (
  50: #ece1e8,
  100: #d0b4c5,
  200: #b0829e,
  300: #904f77,
  400: #792a5a,
  500: #61043d,
  600: #590337,
  700: #4f032f,
  800: #450227,
  900: #33011a,
  A100: #ff6ba5,
  A200: #ff3885,
  A400: #ff0566,
  A700: #eb005b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-app-warn: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

$color-primary: map-get($mt-app-primary, 500);
$color-primary-active: map-get($mt-app-primary, 800);
$color-primary-contrast: map-deep-get($mt-app-primary, contrast, 500);

$color-accent: map-get($mt-app-accent, 500);
$color-accent-active: map-get($mt-app-accent, 900);
$color-accent-contrast: map-deep-get($mt-app-accent, contrast, 500);

$color-warn: map-get($mat-app-warn, 500);
$color-warn-contrast: map-deep-get($mat-app-warn, contrast, 500);

$color-secondary: #f55b01;
$color-secondary-active: #f55b01;
$color-secondary-contrast: #ffffff;

$color-tertiary: #000000;
$color-tertiary-active: #000000;
$color-tertiary-contrast: #ffffff;

$color-neutral: #231f20;
$color-neutral-active: #000000;
$color-neutral-contrast: #ffffff;
