@charset "UTF-8";
* {
  -webkit-overflow-scrolling: touch;
}
body {
  /*-webkit-transform: translate3d(0, 0, 0);/* fix safari scroll bug when using "-webkit-overflow-scrolling: touch"*/
  line-height: 1.35;
  /* font-family:  -apple-system, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', "微軟正黑體", "Microsoft Jhenghei", sans-serif, "新細明體", "PMingLiU","細明體", "MingLiU", "儷黑 Pro",	"LiHei Pro","文泉驛正黑", "WenQuanYi Zen Hei"; */
}
*:not(input):not(textarea):not(select) {
  /* disable selection/Copy of UIWebView */
  /* -webkit-user-select: none;  */
  /* disable the IOS popup when long-press on a link */
  /* -webkit-touch-callout: none;  */
}
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}

a {
  display: inline-block; /* For img in a */
}

a img {
  border: none; /*For IE*/
  display: block;
  margin: auto;
}

.bold {
  font-weight: bold;
}
.strike {
  text-decoration: line-through;
}
.price-align {
  text-align: right;
}
.qty-align {
  text-align: center;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
.flex-content-center {
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-content {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
}

.flex-content.flex-center {
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}

.flex-content.flex-x-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-content.flex-y-center {
  -webkit-align-items: center;
  align-items: center;
}

.flex-content.flex-top {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.flex-content.flex-right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-content.flex-bottom {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.flex-content.flex-left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.float-right {
  float: right;
}

.gallery {
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  -webkit-justify-content: space-around;
  -webkit-align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: stretch;
  /*align-content: space-around;*/
}
.gallery-item {
  flex: 0 0 8rem;
  padding: 0.5rem;
  margin: 0.5rem;
}
.gallery-item:empty,
.empty-gallery-item {
  visibility: hidden !important;
  height: 0px !important;
  border: none !important;
  margin: 0 0.5rem !important;
  padding: 0 0.5rem !important;
}
.error-msg {
  color: #f44336;
  font-size: 0.8rem;
}
.input-field-hint-msg {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  word-break: break-word;
  white-space: pre-line;
  padding: 0 0.75rem;
  margin-bottom: 1.25rem;
}
.required:after {
  color: #f44336;
  content: "*";
}
.font-color-red {
  color: #f44336;
}

.font-color-green {
  color: green;
}
.hot-price {
  color: #990000;
  font-weight: bold;
}
.overflow-y-scroll {
  overflow-y: auto;
  box-shadow: inset 0px 25px 25px -25px #d0d0d0, inset 0px -25px 25px -25px #d0d0d0;
}

.width-100-percent {
  width: 100%;
}

.table {
  display: table;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-row {
  display: table-row;
}

.table-header-group {
  display: table-header-group;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.table-column {
  display: table-column;
}
.table-header-column {
  font-weight: bold;
  display: table-column;
}

.opacity-50 {
  opacity: 0.5;
}

.margin-top-1-rem {
  margin-top: 1rem;
}
.margin-top-2-rem {
  margin-top: 2rem;
}

.margin-bottom-1-rem {
  margin-bottom: 1rem;
}

.margin-bottom-2-rem {
  margin-bottom: 2rem;
}

.margin-left-1-rem {
  margin-left: 1rem;
}

.margin-right-1-rem {
  margin-right: 1rem;
}

.padding-1-rem {
  padding: 1rem;
}

.padding-top-1-rem {
  padding-top: 1rem;
}

.padding-left-1-rem {
  padding-left: 1rem;
}

.padding-left-2-rem {
  padding-left: 2rem;
}

.padding-right-1-rem {
  padding-right: 1rem;
}

.font-size-2-rem {
  font-size: 2rem;
}

.font-size-1-5-rem {
  font-size: 1.5rem;
}

/* typography  start*/
.typ-headline{
  font-size: var(--typ-headline-font-size, 2rem);
  line-height: var(--typ-headline-line-height, 2.0);
  font-weight: 700;
}

.typ-title{
  font-size: var(--typ-title-font-size, 1.5rem);
  line-height: var(--typ-title-line-height, 1.5);
  font-weight: 700;
}

.typ-subheading-2{
  font-size: var(--typ-subheading-2-font-size, 1.25rem);
  line-height: var(--typ-subheading-2-line-height, 1.5);
  font-weight: 700;
}

.typ-subheading-1{
  font-size: var(--typ-subheading-1-font-size, 1rem);
  line-height: var(--typ-subheading-1-line-height, 1.5);
}

.typ-body-strong,
.typ-body-2{
  font-size: var(--typ-body-2-font-size, 1rem);
  line-height: var(--typ-body-2-line-height, 1.35);
  font-weight: 700;
}

.typ-body-1{
  font-size: var(--typ-body-1-font-size, 1rem);
  line-height: var(--typ-body-1-line-height, 1.35);
}

.typ-small,
.typ-caption{
  font-size: var(--typ-small-font-size, var(--typ-caption-font-size, 0.825rem));
  line-height: var(--typ-small-line-height, var(--typ-caption-line-height, 1));
}
/* typography  end*/

@media screen and (min-width: 600px) {
  .gt-xs-hidden {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .xs-hidden {
    display: none;
  }
}
