/* You can add global styles to this file, and also import other style files */
@import "~normalize.css/normalize.css";

/* @import '@angular/material/prebuilt-themes/indigo-pink.css'; */
@import "custom-material-styles.scss";

@import "custom-color";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/v4-shims";

@import "~css-star-rating/css/star-rating.min.css";
star-rating,
star-rating-control {
  // @import "~angular-star-rating/assets/scss/star-rating";
  // .rating.color-ok .star-container .star svg {
  //   fill: orange !important;
  // }
  // .rating.color-positive .star-container .star svg {
  //   fill: orange !important;
  // }
  // .rating.color-negative .star-container .star svg {
  //   fill: orange !important;
  // }
  // .rating.color-default .star-container .star svg {
  //   fill: orange !important;
  // }
  .star-container {
    margin-left: 0;
    z-index: 1;
  }
}

@import "~ngx-lightbox/lightbox.css";

ngx-mat-intl-tel-input .country-selector {
  // opacity: 1 !important;
}

ngx-mat-intl-tel-input.force-show-country-selector .country-selector {
  opacity: 1;
}

@import "~swiper/swiper-bundle";
:root {
  // swiper
  --swiper-navigation-color: black;
  --swiper-theme-color: var(--color-primary);
  --swiper-preloader-color: var(--color-primary);
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}

//lib-ngx-image-zoom
// fix not show full size image when cursor hover on thumbnail image on reload
.ngxImageZoomFullContainer.ngxImageZoomLensEnabled .ngxImageZoomFull {
  display: block !important;
}

$color-ngx-slider: black;

html {
  .ngx-slider .ngx-slider-bar {
    background: grey;
  }

  .ngx-slider .ngx-slider-selection {
    // background: var(--color-primary);
    background: $color-ngx-slider;
  }

  .ngx-slider .ngx-slider-pointer {
    // background-color: var(--color-primary);
    background-color: $color-ngx-slider;
  }

  .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }

  .ngx-slider .ngx-slider-bubble {
    // color: var(--color-primary);
    color: $color-ngx-slider;
  }

  .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    // color: var(--color-primary);
    color: $color-ngx-slider;
  }

  .ngx-slider .ngx-slider-tick {
    background: grey;
  }

  .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    // background: var(--color-primary);
    background: $color-ngx-slider;
  }
}

@import "responsive-breakpoints";
// app
:root {
  --color-primary: #{$color-primary};
  --color-primary-active: #{$color-primary-active};
  --color-primary-contrast: #{$color-primary-contrast};

  --color-accent: #{$color-accent};
  --color-accent-active: #{$color-accent-active};
  --color-accent-contrast: #{$color-accent-contrast};

  --color-warn: #{$color-warn};
  --color-warn-contrast: #{$color-warn-contrast};

  --color-secondary: #{$color-secondary};
  --color-secondary-active: #{$color-secondary-active};
  --color-secondary-contrast: #{$color-secondary-contrast};

  --color-tertiary: #{$color-tertiary};
  --color-tertiary-active: #{$color-tertiary-active};
  --color-tertiary-contrast: #{$color-tertiary-contrast};

  --color-neutral: #{$color-neutral};
  --color-neutral-active: #{$color-neutral-active};
  --color-neutral-contrast: #{$color-neutral-contrast};

  --color-nav-bar-bg: white;
  --color-background: #fafafa;
  --color-border: #dbdbdb;

  --color-out-of-stock: var(--color-primary);
  --color-add-cart: var(--color-primary);
  --color-number-of-product: violet;
  --color-list-price: #990000;
  --color-discount-price: #424242;

  --color-app-toolbar: #fafafa;
  --color-page-toolbar: #ededed;

  --color-shopping-cart-title: #d9d9d9;
  --color-primary-background: white;
  --color-cart-amount: black;
  --color-tag: var(--color-primary);
  --color-shopping-flow: var(--color-primary);
  --color-delivery-flow: var(--color-accent);
  --color-navigation-bar: var(--color-secondary);

  --color-router-link-active: var(--color-secondary);
  --color-navigation-title: #080708;

  --color-promotion-label: var(--color-primary);
  --color-promotion-type: #e6ffe6;

  --color-wish-icon: var(--color-primary);

  --color-out-of-stock-label-bg: black;
  --color-out-of-stock-label-color: white;

  --color-footer-bg: var(--color-tertiary);
  --color-footer-text: var(--color-tertiary-contrast);
  --color-footer-text-active: var(--color-tertiary-contrast);

  --color-nav-bar-text: var(--color-accent);
  --color-nav-bar-text-active: var(--color-accent-active);

  // z-index
  --z-index-search-recommend: 2;
}

html {
  @import "common";
}

// :root {
//   --color-nav-bar-bg: #{var(--color-nav-bar-bg)};
//   --color-background: #{var(--color-background)};
//   --color-border: #{var(--color-border)};

//   --color-text: #{var(--color-text)};
//   --color-out-of-stock: #{var(--color-out-of-stock)};
//   --color-add-cart: #{var(--color-add-cart)};
//   --color-number-of-product: #{var(--color-number-of-product)};
//   --color-list-price: #{var(--color-list-price)};
//   --color-discount-price: #{var(--color-discount-price)};

//   --color-app-toolbar: #{$color-app-toolbar};
//   --color-page-toolbar: #{$color-page-toolbar};

//   --color-shopping-cart-title: #{$color-shopping-cart-title};
//   --color-primary-background: #{$color-primary-background};
//   --color-cart-amount: #{$color-cart-amount};
//   --color-tag: #{$color-tag};
//   --color-shopping-flow: #{$color-shopping-flow};
//   --color-delivery-flow: #{$color-delivery-flow};
//   --color-navigation-bar: #{$color-navigation-bar};

//   --color-router-link-active: #f4a641;
// }

* {
  box-sizing: border-box;
  // -webkit-user-select: text;
  // user-select: text;
}

*:not(input):not(textarea):not(select) {
  // user-select: initial;
}

html {
  height: 100%;
  // scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
}

img {
  // image-rendering: crisp-edges;
  // image-rendering: pixelated;
}

.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

[hidden] {
  display: none !important;
}

.page-top-toolbar {
  height: 4.5rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 101;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-card .page-top-toolbar {
  top: -16px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
}

.page-content {
  padding: 1rem;
}
.page-content .mat-card {
  margin-bottom: 1rem;
}

.page-content .form-style div:nth-child(n + 2) .mat-form-field,
.page-content .form-style div:nth-child(n + 2) .mat-slide-toggle,
.page-content .form-style .mat-form-field:nth-child(n + 2) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.page-content .inline-form-style div:nth-child(n + 2) .mat-form-field,
.page-content .inline-form-style .mat-form-field:nth-child(n + 2) {
  margin-left: 0.5rem;
}

.page-content .form-style .mat-form-field {
  width: 100%;
}

.page-content form .mat-form-field .mat-input-element:disabled,
.page-content form .mat-select-disabled .mat-select-value {
  color: inherit;
}

.page-bottom-toolbar {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-card .page-bottom-toolbar {
  bottom: -16px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
}

.page-top-toolbar .mat-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.page-top-toolbar > div:nth-child(1) {
  overflow: hidden;
}

.page-top-toolbar h2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.page-content .page-table-of-contents {
  position: sticky;
  top: 0;
  margin-left: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 2px;
  min-width: 8rem;
}

.page-top-toolbar ~ .page-content .page-table-of-contents {
  // top:4.5rem;
  top: 70px;
}

.page-top-toolbar ~ .page-content .mat-header-row.mat-table-sticky {
  // top:4.5rem !important;
  top: 70px !important;
}

#main-router-container .page-content {
  padding-bottom: 10vh;
}

#image-picker-dialog {
  display: flex;
  flex-flow: column nowrap;
}

.mat-icon.fa::before {
  display: flex;
  height: 100%;
  width: 100%;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.title {
  text-transform: uppercase;
}

.background-color-gray {
  background-color: #d9d9d9;
}

.background-color-light-gray {
  background-color: #f8f8f8;
}

@media (orientation: landscape) {
  .app-lang-zh-HK {
    .mat-calendar .mat-calendar-header .mat-calendar-header-date .mat-calendar-header-date-month {
      font-size: 25px;
      line-height: 27px;
    }
  }
}

@media (orientation: portrait) {
  .mat-calendar .mat-calendar-header .mat-calendar-header-date .mat-calendar-header-date-month {
    font-size: 30px;
    line-height: 36px;
  }
  .mat-calendar .mat-calendar-header.mat-calendar-type-datetime .mat-calendar-header-time-hour,
  .mat-calendar .mat-calendar-header.mat-calendar-type-datetime .mat-calendar-header-time-hour > button {
    font-size: 30px;
    line-height: 36px;
  }
}

.breadcrumb-nav {
  margin-top: 1rem;
  text-transform: uppercase;
  // color: var(--color-primary);
  font-weight: bold;

  a {
    text-decoration: none;
    // color: var(--color-primary);
    color: black;
  }
  a:visited {
    // color: initial;
  }

  .breadcrumb:not(:last-child)::after {
    content: ">";
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    // color: #757575;
  }

  .breadcrumb:last-child {
    font-weight: bold;
  }
}

.flow {
  --color-future-bg: #dcdcdc;
  --color-future-text: #ffffff;
  --color-flow-step-bg: var(--color-secondary);
  --color-flow-step-text: var(--color-secondary-contrast);
  --icon-border-radius: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .flow-step {
    flex: 1 1 auto;

    .flow-link {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }

    .flow-link::before,
    .flow-link::after {
      content: "";
      flex: 1 1 auto;
      //   vertical-align: middle;
      // border-top: 0.5rem solid var(--color-future-bg);
      border-style: solid;
      border-color: var(--color-future-bg);
      border-width: 0;
      border-top-width: 0.5rem;
    }

    .flow-icon {
      //   padding: 0.5rem 1rem;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      line-height: 1;
      font-weight: bold;
      background-color: var(--color-future-bg);
      // color: #707070;
      color: var(--color-future-text);
      border-radius: var(--icon-border-radius);
    }

    .flow-label {
      text-align: center;
      margin: 0.5rem auto auto auto;
      max-width: 10rem;
      overflow: visible;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--color-future-bg);
    }
  }

  .flow-step.pass .flow-link::before,
  .flow-step.pass .flow-link::after,
  .flow-step.active .flow-link::before {
    // border-top: 0.5rem solid var(--color-flow-step-bg);
    border-color: var(--color-flow-step-bg);
  }

  .flow-step.pass .flow-icon,
  .flow-step.active .flow-icon {
    background-color: var(--color-flow-step-bg);
    color: var(--color-flow-step-text);
  }

  .flow-label.active,
  .flow-label.pass {
    color: var(--color-flow-step-bg);
  }

  .flow-step:first-child .flow-link::before,
  .flow-step:last-child .flow-link::after {
    //   display: none;
    border-color: transparent;
  }
}

.flow.flow-vertical {
  flex-direction: column;
  align-items: stretch;

  .flow-step {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

    .flow-link {
      flex: 1 1 30%;
      flex-flow: column nowrap;
    }

    .flow-link::before,
    .flow-link::after {
      border-top-width: 0;
      border-left-width: 0.5rem;
    }

    .flow-label {
      flex: 1 1 70%;
      max-width: none;
      margin: auto;
    }
  }
}

div#wh-widget-send-button {
  bottom: 60px !important;
}

.list-price {
  color: var(--color-list-price);
  text-decoration: line-through;
}

.price {
  color: var(--color-discount-price);
}

.out-of-stock {
  color: var(--color-out-of-stock);
}

.add-cart {
  color: var(--color-add-cart);
}

.number-of-product {
  color: var(--color-number-of-product);
}

// .mat-snack-bar-container.message {
//   background-color: var(--color-add-cart);
// }

.out-of-stock-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  padding: 0.5em 0.5em;
  background-color: rgba(255, 255, 255, 0.9);
}

.out-of-stock-icon {
  // color: red;
  // border-top: 4px solid red;
  // border-bottom: 4px solid red;
  // transform: rotateZ(-10deg);
  // display: inline-block;
  color: var(--color-out-of-stock);
}

button.float-filter-toggler {
  position: fixed;
  top: 15vh;
  z-index: 10;
}

.float-filter-label {
  position: fixed;
  top: 15%;
  background-color: #1d1d1d;
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  img {
    width: 2rem;
  }
}

.html-content-container {
  overflow: auto;
  clear: both;

  &::before,
  &::after {
    content: "";
    clear: both;
    display: block;
  }

  @import "wysiwyg.scss";
}

.adv-pop-up-dialog > .mat-dialog-container {
  box-shadow: none;
  background: transparent;
}

.adv-pop-up-dialog {
  // max-height: 90vh;

  .mat-dialog-container {
    // max-width: 60vw;
    width: fit-content;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
}

.video-gallery-dialog > .mat-dialog-container {
  box-shadow: none;
  background: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

app-showcase.responsive-showcase {
  app-showcase-item,
  .empty-showcase-item {
    flex: 1 1 calc(100% / 5 - 2rem);
  }

  @include xs() {
    app-showcase-item,
    .empty-showcase-item {
      flex: 1 1 calc(100% / 2 - 2rem);
    }
  }

  @include sm() {
    app-showcase-item,
    .empty-showcase-item {
      flex: 1 1 calc(100% / 3 - 2rem);
    }
  }

  @include md() {
    app-showcase-item,
    .empty-showcase-item {
      flex: 1 1 calc(100% / 4 - 2rem);
    }
  }
}

@media screen and (max-width: 599px) {
  div#wh-widget-send-button {
    bottom: 60px !important;
    z-index: 1000 !important;
  }

  div {
    // flex-shrink: 0;
  }
}

@media screen and (max-width: 899px) {
  .adv-pop-up-dialog {
    max-width: 100vw;

    .mat-dialog-container {
      max-width: 100vw;
      // padding: 0;
    }
  }
}
